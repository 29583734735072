<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template
          #default
        >
          <tbody>
            <tr>
              <td>
                <v-chip
                  color="info"
                  dark
                  label
                  small
                >
                  {{ currentDate|shortDate }}
                </v-chip>
              </td>
              <td>
                Fecha de última comprobación
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { currentDate } from '@/utils/date'

export default {
  mixins: [internalShowingMixin],
  data () {
    return {
      currentDate: currentDate(),
    }
  },
}
</script>
