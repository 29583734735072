import { likeRegExp } from '@/offline/database'

export default {
  _selectSistemaTviacomunicacionBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.sistema_tviacomunicacion)
      .innerJoin(
        tables.tviacomunicacion,
        tables.sistema_tviacomunicacion.idtviacomunicacion.eq(tables.tviacomunicacion.idtviacomunicacion)
      )
      .orderBy(tables.sistema_tviacomunicacion.orden)
  },
  async selectSistemaTviacomunicacion (Vue, filter, search, sorter, page, idsistema) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.sistema_tviacomunicacion.numero.match(reQ),
          tables.sistema_tviacomunicacion.nabonado.match(reQ),
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.sistema_tviacomunicacion.idsistema.eq(idsistema),
        tables.sistema_tviacomunicacion.estado.gt(0),
        ...whereSearch,
        ...whereFilter
      )
    )
    return [
      await this._selectSistemaTviacomunicacionBase(Vue)
        .where(...where)
        .limit(Vue.$offline.db.ITEMS_PER_PAGE)
        .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
        .exec(),
      []
    ]
  },
  async selectSistemaTviacomunicacionRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    return await this._selectSistemaTviacomunicacionBase(Vue)
      .where(tables.sistema_tviacomunicacion.idsistema_tviacomunicacion.in(pks))
      .exec()
  }
}
